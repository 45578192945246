import React from 'react';
import {Cell, Grid, Row} from '@material/react-layout-grid';
import {message} from '../../utils/message';
import {getUrlParams, getUrlFromUrlParams} from '../../utils/xss';
import { Api } from  '../../api';


export class TokenComponent extends React.Component {
    constructor(props) {
        super(props);
        this.checkToken = this.checkToken.bind(this);
    }

    componentDidMount() {
        this.checkToken()
    }
    checkUri(uri){
        // 增加函数校验,但后端已校验完成
        return uri
    }

    checkToken() {
        let urlParams = new URLSearchParams(window.location.search);
        let sysId = getUrlParams(urlParams, 'sys_id');
        let token = getUrlParams(urlParams, 'token');
        let redirectUri = getUrlFromUrlParams(urlParams, 'redirect_uri');

        console.log("TokenComponent, redirectUri:", redirectUri, ", sysId:", sysId, ", token:", token);
        new Api(this.props.rootState).get(Api.Action.AUTH_CHECK_TOKEN, {
            sys_id: sysId,
            token: token,
        }).then((response) => {
            var d = response.data.data;
            console.log("check token, resp:", response.data);
            if (d["token"]) {
                message.showInfo("登录成功, 即将跳转 :)");

                window.location.href = this.checkUri(redirectUri);
            }
        }).catch((e) => {
            message.showError("鉴权失败, 请联系 「腾讯公共服务」 成员协助解决. (验证token失败), " + e);
        });
    }

    render() {
        return (
            <div className='Ticket'>
                <Grid className="Ticket-grid">
                    <Row>
                        <Cell columns={2}/>

                        <Cell columns={8}>
                            <h1>正在验证 Token, 请稍后 ...</h1>

                            <p>我们正在「 验证 」登录 TOKEN, 这个过程一般会在 3 秒内完成.</p>
                        </Cell>
                        <Cell columns={2}/>
                    </Row>
                </Grid>
            </div>
        )
    }
}
